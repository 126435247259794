import React, { useCallback } from "react";
import {
  Form,
  Field,
  FieldProps,
  FormikErrors,
  FormikProps,
  withFormik,
  FieldInputProps,
} from "formik";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import {
  Input,
  HStack,
  Button,
  Checkbox,
  CheckboxGroup,
  VStack,
  Text,
} from "@chakra-ui/react";
import { ApiClient } from "../api/apiClient";
import { CherryPayApi } from "../api/models";
import { isEmptyStr } from "../util/StringUtil";
import { TextField } from "../components/fields/TextField/TextField";
import { TextArea } from "../components/fields/TextArea/TextArea";
import { FieldWrapper } from "../components/fields/FieldWrapper/FieldWrapper";
import { FormStack } from "../components/FormStack/FormStack";

interface RoleFormValues {
  name: string;
  description: string;
  grantedPermissions: string[];
}

interface RoleFormProps {
  /**
   * The existing cherry pay role if editing an existing role.
   */
  existingRole: CherryPayApi.Role | null;
  /**
   * Permissions that can be assigned to a role.
   */
  availablePermissions: CherryPayApi.Permission[];
  onCancel: () => void;
  onSave: (role: CherryPayApi.Role) => void;

  firstInputRef?: React.RefObject<HTMLInputElement>;
}

const RolePermissionsCheckboxGroup = ({
  disabled,
  field,
  form,
  availablePermissions,
}: {
  field: FieldInputProps<string[]>;
  form: FormikProps<any>;
  availablePermissions: CherryPayApi.Permission[];
  disabled?: boolean;
}) => {
  const onChange = useCallback(
    (val: string[]) => {
      if (disabled) {
        return;
      }
      form.setFieldValue(field.name, val);
    },
    [form.setFieldValue, field.name, disabled]
  );

  return (
    <CheckboxGroup {...field} onChange={onChange}>
      <VStack w="100%">
        {availablePermissions.map(({ Name, DisplayName, Description }) => {
          return (
            <Checkbox key={Name} value={Name} w="100%" title={Description}>
              {DisplayName}
            </Checkbox>
          );
        })}
      </VStack>
    </CheckboxGroup>
  );
};

const InnerForm = ({
  existingRole,
  isSubmitting,
  isValid,
  onCancel,
  availablePermissions,
  dirty,
  submitForm,
}: RoleFormProps & FormikProps<RoleFormValues>) => {
  return (
    <FormStack>
      <TextField
        id="name-field"
        label="Name"
        name="name"
        isReadOnly={!!existingRole}
      />
      <TextArea id="description-field" label="Description" name="description" />
      <FieldWrapper name="grantedPermissions" label="Permissions">
        {({ field, form }) => (
          <>
            <FormHelperText mb="2">
              Choose which permissions should be assigned to this role.
            </FormHelperText>
            <RolePermissionsCheckboxGroup
              availablePermissions={availablePermissions}
              form={form}
              field={field}
            />
          </>
        )}
      </FieldWrapper>

      <HStack width="100%" justifyContent="end" spacing="3" pt="8">
        <Button
          colorScheme="cherryButton"
          disabled={isSubmitting || !isValid || !dirty}
          onClick={submitForm}
        >
          Save
        </Button>
        <Button disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
      </HStack>
    </FormStack>
  );
};

export const RoleForm = withFormik<RoleFormProps, RoleFormValues>({
  mapPropsToValues: (props) => {
    return {
      name: props.existingRole?.Name ?? "",
      description: props.existingRole?.Description ?? "",
      grantedPermissions: props.existingRole?.GrantedPermissions ?? [],
    };
  },

  handleSubmit: async (values, { props }) => {
    props.onSave({
      Name: values.name.trim(),
      Description: values.description.trim(),
      GrantedPermissions: values.grantedPermissions,
    });
  },

  validateOnBlur: true,
  validateOnMount: true,

  validate: (values) => {
    let errors: FormikErrors<RoleFormValues> = {};

    if (!/^[a-zA-Z0-9 ]+$/.test(values.name ?? "")) {
      errors.name = "Name must only contain alphanumeric characters";
    }

    if (isEmptyStr(values.name)) {
      errors.name = "Name is required.";
    }

    if (values.grantedPermissions.length === 0) {
      errors.grantedPermissions = "A role must have permissions assigned.";
    }

    return errors;
  },
})(InnerForm);
