import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { useUserContext } from "../../context/UserContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { usePaginatedApiRequest } from "../../hooks/usePaginatedApiRequest";
import { useApiClient } from "../../hooks/useApiClient";
import { Card } from "../../components/Card/Card";
import DateUtil from "../../util/DateUtil";
import { useToast } from "../../hooks/useToast";

export const CherryPayCardsByMember = () => {
  const apiClient = useApiClient();
  const business = useBusinessContext();
  const { userBusinessId } = useUserContext();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [program, setProgram] = useState<string>();
  const [filter, setFilter] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const { showErrorToast, showSuccessToast } = useToast();

  const {
    data: configurations,
    error: configurationError,
    isLoading: configLoading,
  } = useApiRequest(
    (apiClient) => apiClient.getConfiguration(business.BusinessId),
    [business]
  );

  const {
    items: cards,
    isLoading: reportLoading,
    isLoadingPage: reportLoadingPage,
    error: reportError,
    fetchNextPage,
  } = usePaginatedApiRequest(
    (apiClient) => {
      const start = DateUtil.setStartEndDate(startDate, "start");
      const end = DateUtil.setStartEndDate(endDate, "end");
      return apiClient.getCardMembersReport(
        business.BusinessId,
        limit,
        program,
        start,
        end
      );
    },
    (apiClient, continuationToken) => {
      const start = DateUtil.setStartEndDate(startDate, "start");
      const end = DateUtil.setStartEndDate(endDate, "end");
      return apiClient.getCardMembersReport(
        business.BusinessId,
        limit,
        program,
        start,
        end,
        continuationToken
      );
    },
    [business, filter]
  );

  const programTypeOptions = useMemo(
    () =>
      configurations?.CardPrograms.map((p) => {
        return (
          <option value={p.CardProgramId} key={p.CardProgramId}>
            {p.Description}
          </option>
        );
      }),
    [configurations]
  );

  const cardRows = useMemo(
    () =>
      cards?.map((c) => {
        const formattedDate = DateUtil.getLocalDateFormatFromString(
          c.CardCreatedDateTime
        );
        return (
          <Tr key={c.CardId}>
            <Td whiteSpace="nowrap">{c.Email}</Td>
            <Td whiteSpace="nowrap">{c.FirstName}</Td>
            <Td whiteSpace="nowrap">{c.LastName}</Td>
            <Td whiteSpace="nowrap">{c.Phone}</Td>
            <Td whiteSpace="nowrap">{c.MembershipNumber}</Td>
            <Td whiteSpace="nowrap">{c.MembershipType}</Td>
            <Td whiteSpace="nowrap">{c.MembershipLevel}</Td>
            <Td whiteSpace="nowrap">{c.ExternalAccountId}</Td>
            <Td whiteSpace="nowrap">{c.CardProgramType}</Td>
            <Td whiteSpace="nowrap">{formattedDate}</Td>
            <Td whiteSpace="nowrap">{c.CardIssuedByUser}</Td>
            <Td whiteSpace="nowrap">${c.InitialCardValue}</Td>
          </Tr>
        );
      }),
    [cards]
  );

  const run = () => {
    setFilter(filter + 1);
  };

  const downloadCsv = async () => {
    const start = DateUtil.setStartEndDate(startDate, "start");
    const end = DateUtil.setStartEndDate(endDate, "end");
    const qresult = apiClient.getCardByMembersReport(
      business.BusinessId,
      program,
      start,
      end
    );

    qresult.then((result) => {
      if (result.ok) {
        const blobData = new Blob([result.data]);
        const url = URL.createObjectURL(blobData);
        const link = document.createElement("a");
        link.download = `cherrypay-cards-by-member-${business.BusinessId}-${
          program ?? ""
        }-${startDate ?? ""}-${endDate ?? ""}.csv`;
        link.href = url;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (result.statusCode == 404) showErrorToast(result.message);
      else {
        showErrorToast("Failed to download the report");
      }
    });
  };

  return (
    <>
      <PageHeading>
        <Breadcrumb>
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/businesses">
                Businesses
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/businesses/${business.BusinessId}`}>
              {business.DisplayName}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${business.BusinessId}/reports`}
            >
              Reports
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageHeading.Title>CherryPay Cards by Member Report</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <VStack alignItems="start" width="100%">
          <Card>
            <Flex gridGap={6} className="form-filter-wrapper">
              {configurations && configurations.CardPrograms.length > 0 && (
                <Box>
                  <FormControl>
                    <FormLabel>Program</FormLabel>
                    <Select
                      placeholder="Select program"
                      onChange={(e) => setProgram(e.target.value)}
                      defaultValue={program}
                    >
                      {programTypeOptions}
                    </Select>
                  </FormControl>
                </Box>
              )}
              {configLoading && (
                <Center>
                  <Spinner />
                </Center>
              )}
              {configurationError && (
                <ErrorMessage>
                  An error was encountered while loading the program types.
                </ErrorMessage>
              )}
              <Box>
                <FormControl>
                  <FormLabel>Issued from</FormLabel>
                  <Input
                    type="date"
                    placeholder="Issued from"
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel>Issued to</FormLabel>
                  <Input
                    type="date"
                    placeholder="Issued to"
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel>Rows</FormLabel>
                  <Select
                    onChange={(e) => setLimit(parseInt(e.target.value))}
                    value={limit}
                    width="20"
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </Select>
                </FormControl>
              </Box>
              <Box alignSelf="flex-end" className="form-filter-button-wrapper">
                <Button colorScheme="cherryButton" onClick={run}>
                  Run
                </Button>
                <Button marginLeft="4" onClick={downloadCsv}>
                  Download
                </Button>
              </Box>
            </Flex>
          </Card>
          <Card width="100%">
            {!cards && reportLoading && (
              <Center>
                <Spinner margin="4" />
              </Center>
            )}
            <Box width="100%">
              <TableContainer alignSelf="start" width="100%" overflowX="auto">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th whiteSpace="nowrap">Email</Th>
                      <Th whiteSpace="nowrap">First Name</Th>
                      <Th whiteSpace="nowrap">Last Name</Th>
                      <Th whiteSpace="nowrap">Phone</Th>
                      <Th whiteSpace="nowrap">Membership No.</Th>
                      <Th whiteSpace="nowrap">Membership Type</Th>
                      <Th whiteSpace="nowrap">Membership Level</Th>
                      <Th whiteSpace="nowrap">External Account ID</Th>
                      <Th whiteSpace="nowrap">Card Program Type</Th>
                      <Th whiteSpace="nowrap">Card Created Date</Th>
                      <Th whiteSpace="nowrap">Card Issued By</Th>
                      <Th whiteSpace="nowrap">Initial Card Value</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {(cards?.length && cardRows) || (
                      <Tr>
                        <Td colSpan={12} fontStyle="italic" textAlign="center">
                          No cards data found
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Card>
          {fetchNextPage && (
            <Card>
              {!reportLoadingPage && (
                <Button colorScheme="cherryButton" onClick={fetchNextPage}>
                  Load more...
                </Button>
              )}
              {reportLoadingPage && <Spinner />}
            </Card>
          )}
          {reportError != null && (
            <Card>
              <ErrorMessage>
                An error was encountered while getting report. {reportError}
              </ErrorMessage>
            </Card>
          )}
        </VStack>
      </PageContent>
    </>
  );
};
