import React, { useEffect, useMemo } from "react";
import { chakra } from "@chakra-ui/react";
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  FormikErrors,
  FormikProps,
  withFormik,
} from "formik";
import { Button } from "@chakra-ui/button";
import { Box, HStack, VStack } from "@chakra-ui/layout";

import { ApiClient, ApiResult } from "../api/apiClient";
import { CherryPayApi } from "../api/models";
import { TextField } from "../components/fields/TextField/TextField";
import { SwitchField } from "../components/fields/SwitchField/SwitchField";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import { PlusIcon, TrashIcon } from "../styles/icons";
import { Checkbox } from "@chakra-ui/checkbox";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { IconButton } from "@chakra-ui/react";
import { isEmptyStr } from "../util/StringUtil";
import { FormStack } from "../components/FormStack/FormStack";

const emptyStrToNull = (value: string | null): string | null => {
  if (!value || value.trim() === "") {
    return null;
  } else {
    return value;
  }
};

interface EditVenueFormValues {
  venueNo?: number;
  displayName: string;
  defaultMembershipIntegrationId: string;
  defaultLoyaltyIntegrationId: string;
  defaultGamingIntegrationId: string;
  enabled: boolean;
  pointsTransactionConfiguration: {
    minimumHoldTransactionTimeToLiveSeconds: number | "";
    maximumHoldTransactionTimeToLiveSeconds: number | "";
  };
  pointsTypes: {
    pointsTypeId: string;
    pointsToDollarRatio: number;
    isDefault: boolean;
  }[];

  newPointsTypeId: string;
  newPointsTypeDollarRatio: number | "";
}

interface EditVenueFormProps {
  apiClient: ApiClient;
  business: CherryPayApi.BusinessSummary;
  venue: CherryPayApi.VenueDocument | null;
  isDisabled?: boolean;
  onCancel: () => void;
  onSuccess?: (venue?: CherryPayApi.VenueDocument) => void;
  onFailure?: (message?: string) => void;
}

const PointsTypes = ({ values }: { values: EditVenueFormValues }) => {
  return (
    <FieldArray name="pointsTypes">
      {(props) => {
        const isInvalid = !!props.form.errors.pointsTypes;
        return (
          <FormControl isInvalid={isInvalid}>
            <FormLabel>Points Types</FormLabel>
            <VStack
              border={isInvalid ? "1px solid red" : undefined}
              w="100%"
              padding="1"
            >
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Points Type</Th>
                    <Th>Dollar Ratio</Th>
                    <Th width="0.1%" whiteSpace="nowrap">
                      Default
                    </Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {values.pointsTypes.map((item, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{item.pointsTypeId}</Td>
                        <Td>{item.pointsToDollarRatio}</Td>
                        <Td width="0.1%" whiteSpace="nowrap" textAlign="center">
                          <Field name={`pointsTypes.${index}.isDefault`}>
                            {({ field }: FieldProps<any, any>) => (
                              <Checkbox {...field} isChecked={field.value} />
                            )}
                          </Field>
                        </Td>
                        <Td width="0.1%" whiteSpace="nowrap" textAlign="center">
                          <IconButton
                            aria-label="Remove points type."
                            title="Remove points type."
                            icon={<TrashIcon />}
                            onClick={() => {
                              props.remove(index);
                            }}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                  {values.pointsTypes.length === 0 && (
                    <Tr>
                      <Td colSpan={4}>No Point Types Configured</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>

              <HStack w="100%">
                <Box flex="2">
                  <TextField
                    name={`newPointsTypeId`}
                    label="Points Type Id"
                    placeholder="Type id"
                  />
                </Box>
                <Box flex="1">
                  <TextField
                    name={`newPointsTypeDollarRatio`}
                    label="Dollar Ratio"
                    placeholder="Ratio"
                    type="number"
                  />
                </Box>
              </HStack>
              <HStack w="100%">
                <Button
                  leftIcon={<PlusIcon />}
                  isDisabled={
                    isEmptyStr(values.newPointsTypeId) ||
                    !values.newPointsTypeDollarRatio
                  }
                  onClick={() => {
                    props.push({
                      pointsTypeId: values.newPointsTypeId,
                      pointsToDollarRatio: values.newPointsTypeDollarRatio,
                      // If we are adding the first points type, set it to be the default
                      isDefault: values.pointsTypes.length === 0 ? true : false,
                    });
                    // Clear the fields
                    props.form.setFieldValue("newPointsTypeId", "");
                    props.form.setFieldValue("newPointsTypeDollarRatio", "");
                  }}
                >
                  Add New Points Type
                </Button>
              </HStack>
            </VStack>
            <FormErrorMessage>{props.form.errors.pointsTypes}</FormErrorMessage>
          </FormControl>
        );
      }}
    </FieldArray>
  );
};

const InnerForm = ({
  isSubmitting,
  isDisabled,
  submitForm,
  isValid,
  onCancel,
  venue,
  values,
}: EditVenueFormProps & FormikProps<EditVenueFormValues>) => {
  const isNewVenue = !venue;
  const isDeletedVenue = !!venue?._Meta?.DateDeleted;

  return (
    <FormStack>
      {!!venue && (
        <TextField label="Venue No" name="venueNo" isDisabled={true} />
      )}
      <TextField label="Display Name" name="displayName" />

      <SwitchField label="Enabled" name="enabled" />

      <TextField
        label="Loyalty Integration Id"
        name="defaultLoyaltyIntegrationId"
      />
      <TextField
        label="Membership Integration Id"
        name="defaultMembershipIntegrationId"
      />
      <TextField
        label="Gaming Integration Id"
        name="defaultGamingIntegrationId"
      />

      {!isNewVenue && (
        <TextField
          label="Minimum Transaction Hold TTL Seconds"
          title="Points Transaction Hold Time To Live Seconds"
          name="pointsTransactionConfiguration.minimumHoldTransactionTimeToLiveSeconds"
          placeholder="Minimum Transaction Hold TTL"
          isReadOnly={true}
          isDisabled={true}
        />
      )}
      <TextField
        label="Maximum Transaction Hold TTL Seconds"
        title="Points Transaction Hold Time To Live Seconds"
        name="pointsTransactionConfiguration.maximumHoldTransactionTimeToLiveSeconds"
        placeholder="Maximum Transaction Hold TTL"
        type="number"
        isReadOnly={!isNewVenue}
        isDisabled={!isNewVenue}
      />

      <PointsTypes values={values} />

      <HStack width="100%" justifyContent="end" spacing="3" pt="8">
        <Button
          colorScheme="cherryButton"
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting || !isValid || isDeletedVenue}
          onClick={submitForm}
        >
          Save
        </Button>
        <Button disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
      </HStack>
    </FormStack>
  );
};

export const EditVenueForm = withFormik<
  EditVenueFormProps,
  EditVenueFormValues
>({
  mapPropsToValues: ({ venue }) => {
    return {
      venueNo: venue?.VenueNo ?? undefined,
      displayName: venue?.DisplayName ?? "",
      defaultLoyaltyIntegrationId: venue?.DefaultLoyaltyIntegrationId ?? "",
      defaultMembershipIntegrationId:
        venue?.DefaultMembershipIntegrationId ?? "",
      defaultGamingIntegrationId: venue?.DefaultGamingIntegrationId ?? "",
      enabled: venue?.Enabled ?? true,
      pointsTransactionConfiguration: {
        minimumHoldTransactionTimeToLiveSeconds:
          venue?.PointsTransactionConfiguration
            ?.MinimumHoldTransactionTimeToLiveSeconds ?? "",
        maximumHoldTransactionTimeToLiveSeconds:
          venue?.PointsTransactionConfiguration
            ?.MaximumHoldTransactionTimeToLiveSeconds ?? "",
      },
      pointsTypes:
        venue?.PointsTypes?.map((venuePointsType) => ({
          pointsTypeId: venuePointsType.PointsTypeId,
          pointsToDollarRatio: venuePointsType.PointsToDollarRatio,
          isDefault: venuePointsType.IsDefault === true,
        })) ?? [],
      newPointsTypeId: "",
      newPointsTypeDollarRatio: "",
    };
  },

  handleSubmit: async (values, { props, setFieldError }) => {
    if (isEmptyStr(values.displayName)) {
      setFieldError("displayName", "Display name is required.");
      return;
    }

    if (
      values.pointsTransactionConfiguration
        .maximumHoldTransactionTimeToLiveSeconds === ""
    ) {
      setFieldError(
        "pointsTransactionConfiguration.maximumHoldTransactionTimeToLiveSeconds",
        "Maximum hold transaction TTL is required."
      );
      return;
    }

    // Translate form state to VenueDocument
    const venueDoc: Partial<CherryPayApi.VenueDocument> = {
      DisplayName: values.displayName,
      DefaultLoyaltyIntegrationId: emptyStrToNull(
        values.defaultLoyaltyIntegrationId
      ),
      DefaultMembershipIntegrationId: emptyStrToNull(
        values.defaultMembershipIntegrationId
      ),
      DefaultGamingIntegrationId: emptyStrToNull(
        values.defaultGamingIntegrationId
      ),
      PointsTransactionConfiguration: {
        ...props.venue?.PointsTransactionConfiguration,
        MaximumHoldTransactionTimeToLiveSeconds:
          values.pointsTransactionConfiguration
            .maximumHoldTransactionTimeToLiveSeconds ?? undefined,
      },
      PointsTypes: values.pointsTypes.map(
        ({ pointsTypeId, pointsToDollarRatio, isDefault }) => ({
          PointsTypeId: pointsTypeId,
          PointsToDollarRatio: pointsToDollarRatio,
          IsDefault: isDefault,
        })
      ),
      Enabled: values.enabled,
    };

    let result: ApiResult<CherryPayApi.VenueDocument>;
    if (props.venue) {
      result = await props.apiClient.updateBusinessVenue(
        props.business.BusinessId,
        props.venue.id,
        venueDoc
      );
    } else {
      result = await props.apiClient.createBusinessVenue(
        props.business.BusinessId,
        venueDoc
      );
    }

    if (result.ok) {
      if (props.onSuccess) {
        props.onSuccess(result.data);
      }
    } else {
      if (props.onFailure) {
        props.onFailure(result.message);
      }
    }
  },

  validateOnBlur: false,
  validateOnMount: false,
  validateOnChange: true,

  validate: (values) => {
    let errors: FormikErrors<EditVenueFormValues> = {};

    for (const pointType of values.pointsTypes) {
      if (isEmptyStr(pointType.pointsTypeId)) {
        errors.pointsTypes = "Invalid points type id.";
      }
      if (typeof pointType.pointsToDollarRatio !== "number") {
        errors.pointsTypes = "Invalid points to dollar ratio.";
      }
    }

    if (values.pointsTypes.length > 0) {
      const defaultPointsTypeCount = values.pointsTypes.reduce(
        (count, { isDefault }) => (isDefault ? count + 1 : count),
        0
      );
      if (defaultPointsTypeCount < 1) {
        errors.pointsTypes = "There must be one default points type.";
      }

      if (defaultPointsTypeCount > 1) {
        errors.pointsTypes = "There can only be one default points type.";
      }
    }

    return errors;
  },
})(InnerForm);
