import React from "react";
import { Form, FormikErrors, FormikProps, withFormik } from "formik";
import { HStack, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { ApiClient } from "../api/apiClient";
import { CherryPayApi } from "../api/models";
import { isEmptyStr } from "../util/StringUtil";
import { TextField } from "../components/fields/TextField/TextField";
import { FormStack } from "../components/FormStack/FormStack";
import { isValidEmail } from "../util/isValidEmail";

interface NewUserFormValues {
  email: string;
  name: string;
}

interface NewUserFormProps {
  apiClient: ApiClient;
  businessId: string;
  onCancel: () => void;
  /** Callback to be invoked once the new user account has been created */
  onSuccess?: (user: CherryPayApi.UserAccount) => void;
  onFailure?: (message?: string) => void;
}

const InnerForm = ({
  isSubmitting,
  touched,
  errors,
  isValid,
  onCancel,
}: NewUserFormProps & FormikProps<NewUserFormValues>) => {
  return (
    <FormStack>
      <TextField name="name" label="Name" placeholder="Name" />
      <TextField name="email" label="Email" placeholder="Email" />

      <HStack width="100%" justifyContent="end" spacing="3" pt="8">
        <Button
          isLoading={isSubmitting}
          colorScheme="cherryButton"
          color="#fff"
          type="submit"
          disabled={isSubmitting || !isValid}
        >
          Save
        </Button>
        <Button disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
      </HStack>
    </FormStack>
  );
};

export const NewUserForm = withFormik<NewUserFormProps, NewUserFormValues>({
  mapPropsToValues: (props) => {
    return {
      email: "",
      name: "",
    };
  },

  handleSubmit: async (values, { props, setFieldError }) => {
    let isInvalid = false;
    if (isEmptyStr(values.name)) {
      setFieldError("name", "Name is required");
      isInvalid = true;
    }

    if (isEmptyStr(values.email)) {
      setFieldError("email", "Email is required");
      isInvalid = true;
    }

    if (isInvalid) {
      return;
    }

    // convert to lowercase
    values.email = values.email?.toLowerCase();

    const result = await props.apiClient.createUser(
      {
        Username: values.email,
        DisplayName: values.name,
      },
      props.businessId
    );

    if (result.ok) {
      if (props.onSuccess) {
        props.onSuccess(result.data);
      }
    } else {
      if (props.onFailure) {
        props.onFailure(result.message);
      }
    }
  },

  validateOnMount: false,
  validateOnBlur: true,

  validate: (values) => {
    let errors: FormikErrors<NewUserFormValues> = {};

    if (!isEmptyStr(values.email) && !isValidEmail(values.email)) {
      errors.email = "Invalid email address";
    }

    return errors;
  },
})(InnerForm);
