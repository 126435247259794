export const isEmptyStr = (value: null | undefined | string) => {
  return value === undefined || value === null || value.trim() === "";
};

export const emptyStrToNull = (
  value: string | null | undefined
): string | null => {
  if (!value || value.trim() === "") {
    return null;
  } else {
    return value;
  }
};
