import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Grid, GridItem, Heading, Link } from "@chakra-ui/layout";
import Geosuggest, { Suggest } from "react-geosuggest";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormikErrors, useFormik } from "formik";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { isEmptyStr } from "../util/StringUtil";
import { Collapse, InputGroup, InputLeftAddon, Text } from "@chakra-ui/react";
import { CherryPayApi } from "../api/models";
import { ErrorMessage } from "../components/ErrorMessage/ErrorMessage";
import { useApiClient } from "../hooks/useApiClient";
import { isLocalAustralianMobileNumber } from "../util/isLocalAustralianMobileNumber";
import { transformLocalAustralianMobileNumber } from "../util/transformLocalAustralianMobileNumber";
import { useBusinessContext } from "../context/ModelContext";

interface EditMemberDetailsProps {
  businessId: string | null | undefined;
  memberId: string | null | undefined;
  orderId: string | null | undefined;
  // pre-filled form values
  cardOrder: EditMemberForm | undefined;
  cardProgramId: string;
  dataValidated: (data: CherryPayApi.CardOrderResponse) => void;
  onCancel: () => void;
}

interface EditMemberForm {
  MembershipNumber: string | null;
  FirstName: string | null;
  LastName: string | null;
  MobileNumber: string | null;
  EmailAddress: string | null;
  FormattedAddress: string | null;
  AddressComponents: CherryPayApi.AddressComponent[];
  InitialCardValue: number;
}

export const EditMemberDetailsForm = (props: EditMemberDetailsProps) => {
  const isMounted = useRef(false);
  const apiClient = useApiClient();
  const business = useBusinessContext();
  const [businessConfiguration, setBusinessConfiguration] =
    useState<CherryPayApi.CardProgram>();
  const [submitError, setSubmitError] = useState<string>("");
  const [hasInitializedFormValues, setHasInitializedFormValues] =
    useState(false);
  const [formValues, setFormValues] = useState<EditMemberForm>({
    MembershipNumber: null,
    FirstName: null,
    LastName: null,
    MobileNumber: null,
    EmailAddress: null,
    FormattedAddress: null,
    AddressComponents: [],
    InitialCardValue: 0,
  });

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    //if (props.cardOrder && !hasInitializedFormValues) {
    setFormValues({
      FirstName: props.cardOrder?.FirstName ?? "",
      LastName: props.cardOrder?.LastName ?? "",
      EmailAddress: props.cardOrder?.EmailAddress ?? "",
      FormattedAddress: props.cardOrder?.FormattedAddress ?? "",
      AddressComponents: props.cardOrder?.AddressComponents ?? [],
      MembershipNumber: props.cardOrder?.MembershipNumber ?? "",
      MobileNumber: props.cardOrder?.MobileNumber ?? "",
      InitialCardValue: props.cardOrder?.InitialCardValue ?? 0,
    });
    setHasInitializedFormValues(true);
    //}
  }, [props.cardOrder]);

  const getBusinessConfig = async () => {
    const result = await apiClient.getConfiguration(business.BusinessId);
    if (result.ok) {
      if (isMounted.current) {
        const config = result.data.CardPrograms.find(
          (item) => item.CardProgramId === parseInt(props.cardProgramId)
        );
        setBusinessConfiguration(config);
      }
    }
  };

  useEffect(() => {
    getBusinessConfig();
  }, []);

  const formik = useFormik({
    initialValues: formValues ?? {
      MembershipNumber: "",
      FirstName: "",
      LastName: "",
      MobileNumber: "",
      EmailAddress: "",
      FormattedAddress: "",
      AddressComponents: [],
      InitialCardValue: 0,
    },
    onSubmit: async (val, { setSubmitting }) => {
      if (!props.businessId || !props.memberId || !props.orderId) {
        if (isMounted.current) {
          setSubmitError("Error while validating the data");
        }
      } else {
        try {
          const data: CherryPayApi.CardOrderMemberDetailsUpdate = {
            InitialCardValue: val.InitialCardValue,
            MemberDetails: {
              AddressComponents: val.AddressComponents,
              EmailAddress: val.EmailAddress,
              FirstName: val.FirstName,
              FormattedAddress: val.FormattedAddress,
              LastName: val.LastName,
              MembershipNumber: val.MembershipNumber,
              MobileNumber: val.MobileNumber,
            },
          };
          const result = await apiClient.putCardOrderMemberDetail(
            props.businessId,
            props.memberId,
            props.orderId,
            data
          );
          if (!result.ok) {
            if (isMounted.current) {
              setSubmitError(result.message);
            }
          } else {
            if (isMounted.current) {
              props.dataValidated(result.data);
            }
          }
        } catch (error) {
          if (isMounted.current) {
            setSubmitError("Error while validating the data");
          }
        }
      }

      if (isMounted.current) {
        setSubmitting(false);
      }
    },
    validate: (values) => {
      const errors: FormikErrors<EditMemberForm> = {};
      if (isEmptyStr(values.EmailAddress)) {
        errors.EmailAddress = "Email address is required";
      }

      if (
        values.EmailAddress &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.EmailAddress)
      ) {
        errors.EmailAddress = "Invalid email address";
      }

      if (isEmptyStr(values.FirstName)) {
        errors.FirstName = "First name is required";
      }

      if (isEmptyStr(values.LastName)) {
        errors.LastName = "Last name is required";
      }

      if (isEmptyStr(values.MobileNumber)) {
        errors.MobileNumber = "Mobile number is required";
      }

      if (!isEmptyStr(values.MobileNumber)) {
        if (isLocalAustralianMobileNumber(values.MobileNumber!)) {
          errors.MobileNumber = "Mobile number must include country code";
        } else if (!/^\+[0-9]{8,13}$/.test(values.MobileNumber!)) {
          errors.MobileNumber =
            "Invalid mobile number. Use full number with country code.";
        } else if (
          values.MobileNumber!.length < 12 ||
          values.MobileNumber!.length > 12
        ) {
          errors.MobileNumber =
            "Invalid mobile number. It must be exactly 12 characters in length.";
        }
      }

      if (!values.FormattedAddress) {
        errors.FormattedAddress = "Address is required";
      }

      if (!values.InitialCardValue && values.InitialCardValue !== 0) {
        errors.InitialCardValue = "Initial balance is required";
      }

      if (values.InitialCardValue && businessConfiguration) {
        if (
          values.InitialCardValue >=
          businessConfiguration.MaximumInitialCardValue
        ) {
          errors.InitialCardValue = `Initial balance cannot be more than $${businessConfiguration.MaximumInitialCardValue}`;
        }
        if (
          values.InitialCardValue <=
          businessConfiguration.MinimumInitialCardValue
        ) {
          errors.InitialCardValue = `Initial balance must be at least $${businessConfiguration.MinimumInitialCardValue}`;
        }
      }
      return errors;
    },
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const validateAddress = (
    addressComponent: google.maps.GeocoderAddressComponent[]
  ): string => {
    const validComponents = new Map([
      ["country", true],
      ["post code", true],
      ["state", true],
      ["locality", true],
      ["route", true],
    ]);

    const country = addressComponent.find((component) =>
      component.types.includes("country")
    );

    const postalCode = addressComponent.find((component) =>
      component.types.includes("postal_code")
    );

    const administrativeAreaLevel1 = addressComponent.find((component) =>
      component.types.includes("administrative_area_level_1")
    );

    const locality = addressComponent.find(
      (component) =>
        component.types.includes("locality") ||
        component.types.includes("postal_town")
    );

    const route = addressComponent.find((component) =>
      component.types.includes("route")
    );

    validComponents.set("country", country !== null && country !== undefined);
    validComponents.set(
      "post code",
      postalCode !== null && postalCode !== undefined
    );
    validComponents.set(
      "state",
      administrativeAreaLevel1 !== null &&
        administrativeAreaLevel1 !== undefined
    );
    validComponents.set(
      "locality",
      locality !== null && locality !== undefined
    );
    validComponents.set("route", route !== null && route !== undefined);

    // construct error message
    const baseErrorMessage = "You must select an address which includes a";
    let errorMessage: string = "";
    const hasError = Array.from(validComponents.values()).some(
      (v) => v === false
    );
    if (hasError) {
      errorMessage = baseErrorMessage;
    } else {
      return "";
    }
    validComponents.forEach((value, key) => {
      if (!value) {
        if (errorMessage.length === baseErrorMessage.length) {
          errorMessage = `${errorMessage} ${key}`;
        } else {
          errorMessage = `${errorMessage}, ${key}`;
        }
      }
    });
    return errorMessage;
  };

  const onSuggestSelect = (suggest: Suggest) => {
    if (!suggest) return;
    if (!suggest.gmaps?.address_components) return;

    const validationResult = validateAddress(suggest.gmaps?.address_components);
    if (validationResult !== "") {
      formik.setFieldError("FormattedAddress", validationResult);
      return;
    }

    const addressComponents = suggest.gmaps?.address_components.map((item) => {
      return {
        long_name: item.long_name,
        short_name: item.short_name,
        types: item.types,
      };
    });
    if (addressComponents) {
      formik.setFieldValue("AddressComponents", addressComponents);
    }
    if (suggest.gmaps?.formatted_address) {
      formik.setFieldValue(
        "FormattedAddress",
        suggest.gmaps?.formatted_address
      );
    }
  };

  const showAddress = () => {
    return (
      <>
        <Text fontWeight="bold">
          {formik.values.FormattedAddress}{" "}
          <Link
            color="blue.500"
            onClick={() => {
              formik.setFieldValue("FormattedAddress", null);
              formik.setFieldValue("AddressComponents", []);
            }}
            as="a"
          >
            change
          </Link>
        </Text>
      </>
    );
  };

  const canTransformNumber = useMemo(
    () => isLocalAustralianMobileNumber(formik.values.MobileNumber ?? ""),
    [formik.values.MobileNumber]
  );

  const transformedNumber = useMemo(
    () =>
      transformLocalAustralianMobileNumber(formik.values.MobileNumber ?? ""),
    [formik.values.MobileNumber]
  );

  const onClickTransform = () => {
    formik.setFieldValue(
      "MobileNumber",
      transformLocalAustralianMobileNumber(formik.values.MobileNumber ?? "")
    );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Heading as="h5" color="cherryUi.600" size="md">
          Set Card Details
        </Heading>
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(2, 1fr)"
          gap={5}
          pb={10}
          width="50%"
        >
          <GridItem>Initial Balance</GridItem>
          <GridItem>
            <FormControl
              isInvalid={
                !!formik.errors.InitialCardValue &&
                formik.touched.InitialCardValue
              }
            >
              <InputGroup>
                <InputLeftAddon children="$" />
                <Input
                  type="number"
                  name="InitialCardValue"
                  placeholder="Initial balance"
                  value={formik.values.InitialCardValue}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </InputGroup>
              {formik.errors.InitialCardValue &&
                formik.touched.InitialCardValue && (
                  <FormErrorMessage>
                    {formik.errors.InitialCardValue}
                  </FormErrorMessage>
                )}
            </FormControl>
          </GridItem>
        </Grid>
        <Heading as="h5" color="cherryUi.600" size="md">
          Set Member Details
        </Heading>
        <Grid
          templateRows="repeat(5, 1fr)"
          templateColumns="repeat(4, 1fr)"
          gap={5}
          maxW="1000px"
        >
          <GridItem>First Name</GridItem>
          <GridItem>
            <FormControl
              isInvalid={!!formik.errors.FirstName && formik.touched.FirstName}
            >
              <Input
                type="text"
                name="FirstName"
                placeholder="First Name"
                value={formik.values.FirstName ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.FirstName && formik.touched.FirstName && (
                <FormErrorMessage>{formik.errors.FirstName}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem pl={10}>Last Name</GridItem>
          <GridItem>
            <FormControl
              isInvalid={!!formik.errors.LastName && formik.touched.LastName}
            >
              <Input
                type="text"
                name="LastName"
                placeholder="Last Name"
                value={formik.values.LastName ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.LastName && formik.touched.LastName && (
                <FormErrorMessage>{formik.errors.LastName}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem>Email Address</GridItem>
          <GridItem>
            <FormControl
              isInvalid={
                !!formik.errors.EmailAddress && formik.touched.EmailAddress
              }
            >
              <Input
                type="email"
                name="EmailAddress"
                placeholder="Email Address"
                value={formik.values.EmailAddress ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.EmailAddress && formik.touched.EmailAddress && (
                <FormErrorMessage>
                  {formik.errors.EmailAddress}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem pl={10}>Mobile Number</GridItem>
          <GridItem>
            <FormControl
              isInvalid={
                !!formik.errors.MobileNumber && formik.touched.MobileNumber
              }
            >
              <Input
                type="text"
                name="MobileNumber"
                placeholder="Mobile Number"
                value={formik.values.MobileNumber ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Collapse in={canTransformNumber} animateOpacity>
                <Button variant="link" onClick={onClickTransform} size="xs">
                  Use international format: {transformedNumber}
                </Button>
              </Collapse>
              {formik.errors.MobileNumber && formik.touched.MobileNumber && (
                <FormErrorMessage>
                  {formik.errors.MobileNumber}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem>Postal Address</GridItem>
          <GridItem colSpan={3}>
            {!formik.values.FormattedAddress && (
              <FormControl isInvalid={!!formik.errors.FormattedAddress}>
                <Geosuggest
                  placeholder="E.g. 23 Mulburry St"
                  width="100%"
                  onSuggestSelect={onSuggestSelect}
                  types={["address"]}
                  country="au"
                />
                {formik.errors.FormattedAddress && (
                  <FormErrorMessage>
                    {formik.errors.FormattedAddress}
                  </FormErrorMessage>
                )}
              </FormControl>
            )}
            {formik.values.FormattedAddress && showAddress()}
          </GridItem>
          <GridItem colSpan={4}>
            <Button
              type="submit"
              colorScheme="cherryButton"
              isDisabled={formik.isSubmitting || !formik.isValid}
            >
              CONFIRM DETAILS
            </Button>
            <Button
              onClick={props.onCancel}
              ml="5"
              isDisabled={formik.isSubmitting}
            >
              CANCEL
            </Button>
          </GridItem>
        </Grid>
      </form>
      {submitError != "" && <ErrorMessage>{submitError}</ErrorMessage>}
    </>
  );
};
