import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Form, FormikErrors, FormikProps, withFormik } from "formik";
import { Box, HStack, Text, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { ApiClient, ApiResult } from "../api/apiClient";
import { CherryPayApi } from "../api/models";
import { isEmptyStr } from "../util/StringUtil";
import { TextField } from "../components/fields/TextField/TextField";
import { useApiRequest } from "../hooks/useApiRequest";
import { Spinner } from "@chakra-ui/spinner";
import { SelectField } from "../components/fields/SelectField/SelectField";
import { TextArea } from "../components/fields/TextArea/TextArea";
import { IconButton } from "@chakra-ui/react";
import { RefreshIcon } from "../styles/icons";
import { MemberInfoBox } from "../components/MemberInfoBox/MemberInfoBox";
import { FormStack } from "../components/FormStack/FormStack";

import { BigNumber } from "bignumber.js";
import { CurrencyField } from "../components/fields/CurrencyField/CurrencyField";
import { IntegerField } from "../components/fields/IntegerField/IntegerField";

interface PointsFormValues {
  currentPointsBalance: number | null;
  pointsTypeId: string;
  dollarAmount: string;
  quantity: string;
  note: string;
  description: string;
  username: string;
  cherryPayCardId: string | null;
}

interface PointsFormProps {
  apiClient: ApiClient;
  username: string;
  businessId: string;
  member: CherryPayApi.Member;
  pointsTypes: CherryPayApi.PointsType[];
  cards?: CherryPayApi.CherryPayCardListItem[];
  minimum?: number | null;
  maximum?: number | null;
  formType: "add-points" | "transfer-points-to-cpc";
  onCancel: () => void;
  onSuccess: (message: string) => void;
  onFailure: (message: string) => void;
}

const MemberPoints = ({
  balance,
  isLoading,
  onRefresh,
}: {
  balance: CherryPayApi.PointsBalance | null;
  isLoading: boolean;
  onRefresh: () => void;
}) => {
  return (
    <Box>
      {isLoading && <Spinner size="sm" />}
      {!isLoading && balance && (
        <VStack spacing="0" alignItems="start">
          <Text>{`${balance.DisplayName} Points Balance`}</Text>
          <Text>
            ${balance.PointsBalanceDollarValue}{" "}
            <IconButton
              aria-label="Refresh balance"
              title="Refresh balance"
              size="xs"
              icon={<RefreshIcon />}
              variant="ghost"
              onClick={onRefresh}
            />
          </Text>
        </VStack>
      )}
    </Box>
  );
};

const InnerForm = ({
  isSubmitting,
  isValid,
  onCancel,
  businessId,
  member,
  pointsTypes,
  values,
  setFieldValue,
  formType,
  cards,
}: PointsFormProps & FormikProps<PointsFormValues>) => {
  const memberId = member.id;

  const focusedFieldRef = useRef<string>("");
  const onFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) =>
      (focusedFieldRef.current = e.currentTarget.name),
    [focusedFieldRef]
  );

  const selectedPointsType = useMemo(
    () =>
      pointsTypes.find(
        ({ PointsTypeId }) => PointsTypeId === values.pointsTypeId
      ),
    [pointsTypes, values.pointsTypeId]
  );

  const pointsBalanceRequest = useApiRequest(
    (apiClient) =>
      apiClient.getPointsBalance(businessId, memberId, values.pointsTypeId),
    [businessId, memberId, values.pointsTypeId]
  );

  const submitLabel = useMemo(() => {
    const shouldUseDollarLabel = typeof values.dollarAmount === "number";

    const action = formType === "add-points" ? "Add" : "Transfer";

    return shouldUseDollarLabel
      ? `${action} $${new BigNumber(values.dollarAmount).toFormat(2)}`
      : `${action} Points`;
  }, [formType, values.dollarAmount]);

  // Update the currentPointsBalance which may be used for validation of other fields.
  useEffect(() => {
    setFieldValue(
      "currentPointsBalance",
      pointsBalanceRequest.data && !pointsBalanceRequest.isLoading
        ? pointsBalanceRequest.data?.PointsBalanceQuantity
        : null,
      false
    );
  }, [pointsBalanceRequest.data, pointsBalanceRequest.isLoading]);

  // When the dollar amount is focused and edited, recalculate the quantity.
  useEffect(() => {
    if (focusedFieldRef.current === "dollarAmount" && selectedPointsType) {
      if (values.dollarAmount !== "") {
        const calculatedValue = new BigNumber(values.dollarAmount)
          .multipliedBy(selectedPointsType.PointsToDollarRatio)
          .toFixed();
        setFieldValue(
          "quantity",
          calculatedValue !== "NaN" ? calculatedValue : ""
        );
      } else {
        setFieldValue("quantity", "");
      }
    }
  }, [
    focusedFieldRef,
    values.dollarAmount,
    selectedPointsType?.PointsToDollarRatio,
  ]);

  // When the quantity is focused and edited, recalculate the dollar amount.
  useEffect(() => {
    if (focusedFieldRef.current === "quantity" && selectedPointsType) {
      if (values.quantity !== "") {
        const calculatedValue = new BigNumber(values.quantity)
          .dividedBy(selectedPointsType.PointsToDollarRatio)
          .toFixed(2);
        setFieldValue(
          "dollarAmount",
          calculatedValue !== "NaN" ? calculatedValue : ""
        );
      } else {
        setFieldValue("dollarAmount", "");
      }
    }
  }, [
    focusedFieldRef,
    values.quantity,
    selectedPointsType?.PointsToDollarRatio,
  ]);

  const pointsTypeOptions = useMemo(
    () =>
      pointsTypes.map(({ PointsTypeId, DisplayName }) => ({
        label: DisplayName ?? PointsTypeId,
        value: PointsTypeId,
      })),
    []
  );

  const cardOptions = useMemo(
    () =>
      (cards ?? []).map((card) => ({
        label: card.ExternalAccountId,
        value: card.CardId,
      })),
    [cards]
  );

  return (
    <FormStack>
      <HStack
        w="100%"
        justifyContent="space-between"
        marginBottom="2"
        alignItems="start"
      >
        <Box flex="1">
          <MemberInfoBox member={member} />
        </Box>
        <Box flex="1">
          <MemberPoints
            balance={pointsBalanceRequest.data}
            isLoading={pointsBalanceRequest.isLoading}
            onRefresh={pointsBalanceRequest.refresh}
          />
        </Box>
      </HStack>

      <SelectField
        options={pointsTypeOptions}
        name="pointsTypeId"
        label="Points type"
        allowEmpty={false}
      />

      <HStack w="100%" alignItems="start">
        <CurrencyField
          name="dollarAmount"
          label="Dollar amount"
          placeholder="Amount"
          onFocus={onFocus}
        />
        <IntegerField
          name="quantity"
          label="Points"
          placeholder="Points"
          onFocus={onFocus}
        />
      </HStack>

      {formType === "transfer-points-to-cpc" && (
        <SelectField
          name="cherryPayCardId"
          label="Add funds to member's cherrypay card"
          options={cardOptions}
          allowEmpty={true}
        />
      )}

      {formType === "transfer-points-to-cpc" && (
        <TextField
          name="description"
          label="Description"
          helperText="Transaction description. Visible to customer."
          placeholder="Enter a description"
        />
      )}

      <TextField
        name="note"
        label="Note"
        helperText="Note for administrative purposes only."
        placeholder="Enter a note"
      />

      <TextField
        name="username"
        label={
          formType === "add-points"
            ? "Points awarded by"
            : "Points transferred by"
        }
        isDisabled={true}
      />

      <HStack width="100%" justifyContent="end" spacing="3" pt="8">
        <Button
          isLoading={isSubmitting}
          colorScheme="cherryButton"
          color="#fff"
          type="submit"
          disabled={isSubmitting || !isValid}
        >
          {submitLabel}
        </Button>
        <Button disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
      </HStack>
    </FormStack>
  );
};

export const PointsForm = withFormik<PointsFormProps, PointsFormValues>({
  validateOnMount: false,
  validateOnBlur: false,

  mapPropsToValues: (props) => {
    const defaultPointsType =
      props.pointsTypes.find(({ IsDefault }) => IsDefault === true) ??
      props.pointsTypes[0];

    // If the client only has one card, select it by default.
    const defaultCardId =
      Array.isArray(props.cards) && props.cards.length === 1
        ? props.cards[0].CardId
        : "";

    return {
      pointsTypeId: defaultPointsType.PointsTypeId,
      dollarAmount: "",
      quantity: "",
      note: "",
      description: "",
      username: props.username,
      currentPointsBalance: null,
      cherryPayCardId: defaultCardId,
    };
  },

  handleSubmit: async (values, { props }) => {
    let result: ApiResult<any>;

    if (props.formType === "add-points") {
      result = await props.apiClient.awardPoints(
        props.businessId,
        (props.member as any).id,
        values.pointsTypeId,
        {
          Note: values.note,
          PointsByDollarValue: new BigNumber(values.dollarAmount).toNumber(),
          PointsByQuantity: new BigNumber(values.quantity).toNumber(),
          CherryPayUserName: props.username,
        }
      );
    } else {
      result = await props.apiClient.transferPointsToCard(
        props.businessId,
        (props.member as any).id,
        values.cherryPayCardId!,
        values.pointsTypeId,
        {
          Description: values.description,
          Note: values.note,
          PointsByDollarValue: new BigNumber(values.dollarAmount).toNumber(),
          PointsByQuantity: new BigNumber(values.quantity).toNumber(),
          CherryPayUserName: props.username,
        }
      );
    }

    if (result.ok) {
      props.onSuccess(
        props.formType === "add-points"
          ? "Points awarded to member."
          : "Points transfer was successful."
      );
    } else {
      props.onFailure(
        result.message ?? "An error was encountered while awarding points."
      );
    }
  },

  validate: (values, { pointsTypes, formType, minimum, maximum }) => {
    const selectedPointsType = pointsTypes.find(
      ({ PointsTypeId }) => PointsTypeId === values.pointsTypeId
    );
    let errors: FormikErrors<PointsFormValues> = {};

    if (!selectedPointsType) {
      errors.pointsTypeId = "Invalid points type";
      return errors;
    }

    if (
      formType === "transfer-points-to-cpc" &&
      isEmptyStr(values.cherryPayCardId)
    ) {
      errors.cherryPayCardId = "A card must be selected";
    }

    if (isEmptyStr(values.note)) {
      errors.note = "Note is required.";
    }

    if (
      formType === "transfer-points-to-cpc" &&
      isEmptyStr(values.description)
    ) {
      errors.description = "Description is required";
    }

    if (values.quantity === "" || values.dollarAmount === "") {
      errors.quantity = "Quantity is required";
      errors.dollarAmount = "Amount is required";
      return errors;
    }

    const dollarAmount = new BigNumber(values.dollarAmount);
    const quantity = new BigNumber(values.quantity);

    if (dollarAmount.modulo(0.01).isGreaterThan(0)) {
      errors.dollarAmount = "Invalid dollar amount";
      return errors;
    }

    if (
      !dollarAmount
        .multipliedBy(selectedPointsType.PointsToDollarRatio)
        .isEqualTo(values.quantity)
    ) {
      errors.quantity = "Invalid quantity";
      errors.dollarAmount = "Invalid amount";
    }

    // If the minimum points prop is set
    if (dollarAmount.lt(minimum ?? 0)) {
      errors.dollarAmount = `Minimum point transfer is ${new BigNumber(
        minimum ?? 0
      ).toFormat(2)}`;
    }

    // If the maximum points prop is set
    if (typeof maximum === "number") {
      if (dollarAmount.gt(maximum)) {
        errors.dollarAmount = `Maximum point transfer is ${new BigNumber(
          maximum
        ).toFormat(2)}`;
      }
    }

    // Additional validation specific to a points transfer
    if (formType === "transfer-points-to-cpc") {
      if (
        values.currentPointsBalance !== null &&
        quantity.gt(values.currentPointsBalance)
      ) {
        errors.quantity = "Member does not have enough points";
      }
    }

    return errors;
  },
})(InnerForm);
